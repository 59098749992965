import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Splash from '../components/Splash';
import Navigation from '../components/Navigation';
import Button from '../components/Button';
import { MdChevronRight } from 'react-icons/md';

const Center = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.medium}px) {
    margin-top: 1.5rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.xlarge}px) {
    margin-top: 1rem;
  }
`;

const IndexPageTemplate = (props) => {
  const { data, location } = props;
  const { frontmatter, html } = data.markdownRemark;

  return (
    <Layout location={location} seo={{}}>
      <div>
        <Navigation.Landing.Main />
        <Navigation.Landing.Mobile />
        <Splash.Main {...frontmatter.splash} />
        <Center>
          <Button.Gradient to={'/docs/quick-start'}>
            Get Started
            <MdChevronRight size={22} />
          </Button.Gradient>
        </Center>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </Layout>
  );
};

export default IndexPageTemplate;

export const indexPageQuery = graphql`
  query($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        splash {
          title1
          title2
          subtitle
          buttonText
        }
      }
    }
  }
`;
