import styled from 'styled-components';
import { Link } from 'gatsby';

const Button = styled(Link)`
  display: block;
  border: none;
  cursor: pointer;
  text-align: center;
  border-radius: 8px;
  letter-spacing: 0.75px;
  font-weight: 600;
  box-shadow: ${(p) => p.theme.shadow.main};
  background: ${(p) => p.theme.color.primary.default};
  padding: 0.5rem 1.75rem;
  outline: none;
  color: white;

  &:visited {
    color: white;
  }

  &:active {
    transform: translateY(2px);
    color: white;
  }
`;

export default Button;