import React from 'react';
import styled from 'styled-components';
import LinesBg from './LinesBg';

const Container = styled.div`
  max-width: 80rem;
  min-height: 45vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.small}px) {
    min-height: 57vh;
  }
`;

const Heading = styled.h1`
  overflow-wrap: break-word;
  text-align: center;
  font-size: 2.75rem;
  display: flex;
  flex-direction: column;
  max-width: 80rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.small}px) {
    font-size: 3.25rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.medium}px) {
    font-size: 3.75rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.large}px) {
    font-size: 4.5rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.xlarge}px) {
    font-size: 5rem;
  }
`;

const Lines = styled.div`
  position: absolute;
  width: 100%;
  max-width: 80rem;
  z-index: -1;
`;

const ColoredHeading = styled.span`
  color: ${(p) => p.theme.color.primary.default};
`;

const Subtitle = styled.div`
  max-width: 18rem;
  font-size: 1.05rem;
  text-align: center;
  font-weight: normal;
  line-height: 36px;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.small}px) {
    font-size: 1.15rem;
    max-width: 20rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.medium}px) {
    font-size: 1.2rem;
    max-width: 25rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.large}px) {
    font-size: 1.25rem;
    max-width: 30rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.xlarge}px) {
    font-size: 1.35rem;
  }
`;

const Bg = ({ title1, title2, subtitle }) => {
  return (
    <Container>
      <Heading>
        <span> {title1} </span>
        <ColoredHeading> {title2} </ColoredHeading>
      </Heading>
      <Subtitle>{subtitle}</Subtitle>
      <Lines>
        <LinesBg />
      </Lines>
    </Container>
  );
};

const Main = styled(Bg)``;

export default Main;
