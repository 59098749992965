import styled from 'styled-components';

import Button from './Button';

const GradientButton = styled(Button)`
  background: ${(p) => p.theme.gradient.secondaryTertiary.main};
  color: white;

  &:hover {
    background: ${(p) => p.theme.gradient.secondaryTertiary.dark};
    transition: 0.1s;
  }
`;

export default GradientButton;
