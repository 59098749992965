import React from 'react';

const LinesBg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 3640 1666">
    <defs>
      <linearGradient
        id="a"
        x1="1"
        y1="0.5"
        x2="-0.01"
        y2="0.595"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset="0.493" stopColor="#9e9e9e" stopOpacity="0.65" />
        <stop offset="1" stopColor="#fff" />
      </linearGradient>
      <linearGradient
        id="b"
        x1="1"
        y1="0.5"
        x2="-0.01"
        y2="0.595"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset="0.493" stopColor="#a6a6a6" stopOpacity="0.65" />
        <stop offset="1" stopColor="#fff" />
      </linearGradient>
      <linearGradient
        id="c"
        x1="1"
        y1="0.5"
        x2="-0.01"
        y2="0.595"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset="0.493" stopColor="#adadad" stopOpacity="0.65" />
        <stop offset="1" stopColor="#fff" />
      </linearGradient>
      <linearGradient
        id="d"
        x1="1"
        y1="0.5"
        x2="-0.01"
        y2="0.595"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset="0.493" stopColor="#b3b3b3" stopOpacity="0.65" />
        <stop offset="1" stopColor="#fff" />
      </linearGradient>
      <linearGradient
        id="e"
        x1="1"
        y1="0.5"
        x2="-0.01"
        y2="0.595"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset="0.493" stopColor="#bfbfbf" stopOpacity="0.65" />
        <stop offset="1" stopColor="#fff" />
      </linearGradient>
      <linearGradient
        id="f"
        x1="1"
        y1="0.5"
        x2="-0.01"
        y2="0.595"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset="0.493" stopColor="#ccc" stopOpacity="0.65" />
        <stop offset="1" stopColor="#fff" />
      </linearGradient>
      <linearGradient
        id="g"
        x1="1"
        y1="0.5"
        x2="-0.01"
        y2="0.595"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset="0.493" stopColor="#d9d9d9" stopOpacity="0.65" />
        <stop offset="1" stopColor="#fff" />
      </linearGradient>
      <linearGradient
        id="h"
        x1="1"
        y1="0.5"
        x2="-0.01"
        y2="0.595"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset="0.493" stopColor="#e6e6e6" stopOpacity="0.65" />
        <stop offset="1" stopColor="#fff" />
      </linearGradient>
      <linearGradient
        id="i"
        x1="1"
        y1="0.5"
        x2="-0.01"
        y2="0.595"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset="0.493" stopColor="#f2f2f2" stopOpacity="0.65" />
        <stop offset="1" stopColor="#fff" />
      </linearGradient>
      <clipPath id="k">
        <rect width="3640" height="1666" />
      </clipPath>
    </defs>
    <g id="j" clipPath="url(#k)">
      <rect width="3640" height="1666" fill="#fff" />
      <path
        d="M0,1180"
        transform="translate(64.5 352.5)"
        fill="none"
        stroke="#727272"
        strokeWidth="1"
      />
      <rect
        width="3689.689"
        height="2.39"
        transform="translate(64.757 1642.177) rotate(-18)"
        fill="url(#a)"
      />
      <rect
        width="3689.689"
        height="2.39"
        transform="translate(64.757 1582.177) rotate(-18)"
        fill="url(#b)"
      />
      <rect
        width="3689.689"
        height="2.39"
        transform="translate(64.757 1522.177) rotate(-18)"
        fill="url(#c)"
      />
      <rect
        width="3689.689"
        height="2.39"
        transform="translate(64.757 1462.177) rotate(-18)"
        fill="url(#d)"
      />
      <rect
        width="3689.689"
        height="2.39"
        transform="translate(64.757 1402.177) rotate(-18)"
        fill="url(#e)"
      />
      <rect
        width="3689.689"
        height="2.39"
        transform="translate(64.757 1342.177) rotate(-18)"
        fill="url(#f)"
      />
      <rect
        width="3689.689"
        height="2.39"
        transform="translate(64.757 1282.177) rotate(-18)"
        fill="url(#g)"
      />
      <rect
        width="3689.689"
        height="2.39"
        transform="translate(64.757 1222.177) rotate(-18)"
        fill="url(#h)"
      />
      <circle cx="20" cy="20" r="20" transform="translate(775 1145)" fill="#609dff" />
      <circle cx="20" cy="20" r="20" transform="translate(357 1162)" fill="#f05d5e" />
      <circle cx="20" cy="20" r="20" transform="translate(655 1425)" fill="#2e1cb7" />
      <circle cx="20" cy="20" r="20" transform="translate(217 1445)" fill="#515fcc" />
      <circle cx="20" cy="20" r="20" transform="translate(3126 262)" fill="#fcb07e" />
      <circle cx="20" cy="20" r="20" transform="translate(3025 593)" fill="#0051d3" />
      <circle cx="20" cy="20" r="20" transform="translate(3428 344)" fill="#609dff" />
      <rect
        width="3689.689"
        height="2.39"
        transform="translate(64.757 1162.177) rotate(-18)"
        fill="url(#i)"
      />
    </g>
  </svg>
);

export default LinesBg;
